<template>
    
        <div class="sim-lar-p">
            <div class="image bottom20">
                <router-link v-if="$i18n.locale == 'en'" :to="'/news/'+item.slug">
                    <img 
                        v-lazy="(item.image) ? item.image.url : ''" 
                        :src="(item.image) ? item.image.url : ''" 
                        :alt="(item.image) ?($i18n.locale == 'ar') ? item.image.ar.image_alt : item.image.en.image_alt : ''" 
                        :title="(item.image) ?($i18n.locale == 'ar') ? item.image.ar.image_title : item.image.en.image_title : ''" 
                        class="h235">
                </router-link>
                <router-link v-if="$i18n.locale == 'ar'" :to="'/news/'+item.slug+'?lang=ar'">
                    <img
                            v-lazy="(item.image) ? item.image.url : ''"
                            :src="(item.image) ? item.image.url : ''"
                            :alt="(item.image) ?($i18n.locale == 'ar') ? item.image.ar.image_alt : item.image.en.image_alt : ''"
                            :title="(item.image) ?($i18n.locale == 'ar') ? item.image.ar.image_title : item.image.en.image_title : ''"
                            class="h235">
                </router-link>
            </div>
            
            <div class="sim-lar-text">

                <h3 class="bottom10">
                    <router-link v-if="$i18n.locale == 'en'"
                        :to="'/news/'+item.slug"
                        :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                        {{ ($i18n.locale == 'ar') ? item.title_ar : item.title_en }}
                    </router-link>
                    <router-link v-if="$i18n.locale == 'ar'"
                            :to="'/news/'+item.slug+'?lang=ar'"
                            :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                        {{ ($i18n.locale == 'ar') ? item.title_ar : item.title_en }}
                    </router-link>
                </h3>
                    
                <p><span>{{ item.created_at }}</span></p>

                <p 
                    class="bottom20"
                    :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                    v-html="($i18n.locale == 'ar') ? item.short_body_ar : item.short_body_en">
                </p>

                <router-link v-if="$i18n.locale == 'en'" :to="'/news/'+item.slug" class="btn-more">
                    <i>
                        <img 
                            v-lazy="'/assets/images/arrowl.webp'" 
                            src="/assets/images/arrowl.webp"
                            alt="arrow"
                            title="arrow">
                    </i>
                    <span :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                            {{ $t('app.more_details') }}
                    </span>
                    <i>
                        <img 
                            v-lazy="'/assets/images/arrowr.webp'" 
                            src="/assets/images/arrowr.webp"
                            alt="arrow"
                            title="arrow">
                    </i>
                </router-link>

                <router-link v-if="$i18n.locale == 'ar'" :to="'/news/'+item.slug+'?lang=ar'" class="btn-more">
                    <i>
                        <img
                                v-lazy="'/assets/images/arrowl.webp'"
                                src="/assets/images/arrowl.webp"
                                alt="arrow"
                                title="arrow">
                    </i>
                    <span :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                            {{ $t('app.more_details') }}
                    </span>
                    <i>
                        <img
                                v-lazy="'/assets/images/arrowr.webp'"
                                src="/assets/images/arrowr.webp"
                                alt="arrow"
                                title="arrow">
                    </i>
                </router-link>
            </div>
        </div>

</template>
<script>
export default {
    name: 'NewsCard',
    props: ['item'],
    data(){
        return {
            //
        }
    },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
